.subscribe-popup {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  @media screen and (min-width: 801px) {
    width: 35rem;
    padding: 5rem;
  }
  @media screen and (max-width: 800px) {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .subscribe-popup-tagline, .subscribe-popup-message {
    margin-bottom: 2rem;
    width: calc(100% - 1.5rem);
  }

  .subscribe-popup-tagline {
    font-size: 1.4rem;
  }
  .subscribe-popup-message, .thank-you-message {
    color: #999;
    font-size: 0.9rem
  }
  .subscribe-form-container {
    iframe {
      border: none;
      height: 2rem;
      width: 100%;
    }
  }
}
