@import 'styles/variables.scss';

.products-content {
  max-width: $mainContentWidth + $sidebarWidth * 2;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;

  .products-main-content-outer {
    max-width: $mainContentWidth;
    width: 100%;
  }
  .products-main-content-inner {
    width: fit-content;
    flex-direction: column;
    align-content: center;

    .tagline {
      text-align: center;
      font-family: 'Source Serif Pro', serif;
      color: rgba(102, 102, 102, 0.8);
      font-size: 0.9rem;
    }
  }

  &:not(.page-loaded) {
    .products-header, .sidebar, .group-cell, .product-cell, .tagline {
      opacity: 0;

      .back-link {
        img {
          opacity: 0;
        }
      }
    }
  }
}

@media #{$wideWindow} {
  .products-main-content-outer {
    padding-left: 3rem;
    padding-right: 3rem;

    .products-main-content-inner {
      margin: auto;

      .tagline {
        margin-bottom: 2rem;
      }
    }
  }
}

// groups view - 2 columns
@media only screen and (max-width: 1200px) {
  .products-main-content-inner.groups-view {
    .products-index-container {
      width: 2 * $groupCellWidth;
      margin: auto;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

// groups view - 1 column
@media only screen and (max-width: 875px) {
  .products-main-content-inner.groups-view {
    .products-index-container {
      width: $groupCellWidth;
    }
    .index-header-title {
      .henrybuilt, .group-title {
        font-size: 1.5rem;
      }
    }
  }
  .products-content-groups {
    .sidebar {
      display: block;
    }
  }
}

// products view - 1 column
@media only screen and (max-width: 950px) {
  .products-main-content-inner {
    &.products-view {
      .products-index-container {
        width: $productCellWidth;
        margin: auto;
        margin-top: 1rem;
      }
    }

    .tagline {
      margin: 1rem;
      margin-top: 3.5rem;
    }
  }
}

@media only screen and (max-width: 575px) {
  .products-index-container {
    width: 100%;

    .products-main-content-outer {
      .products-main-content-inner.product-view {
        width: 100%;
      }
    }
  }
}
