@import 'styles/variables.scss';

.product-grid {
  height: auto;
  max-width: 0.8rem + $productCellWidth * 2;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;

  .product-cell {
    opacity: 1;
    transition: $standardTransitionTiming;
    display: inline-block;
    width: $productCellWidth;
    height: 25rem;
    margin: 1px;
    position: relative;
    cursor: pointer;

    &:hover {
      .product-cell-dimmer {
        opacity: 1;
      }
    }

    .product-cell-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .product-cell-dimmer {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s;
      background-color: rgba(0, 0, 0, 0.3);

      .product-cell-title {
        font-size: 1.2rem;
        color: #fff;
        text-transform: lowercase;
        margin: 1rem;
      }
    }
  }
}

@media #{$wideWindow} {

}

@media only screen and (max-width: 1000px) {
  .products-grid {
    width: 0.4rem + $productCellWidth;
    margin: auto;
  }
}
