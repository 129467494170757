.price-display {
  display: flex;
  flex-direction: row;

  &.trade-price-displayed {
    .standard-price {
      opacity: 0.5;
      text-decoration: line-through;
    }
    .trade-price {
      margin-left: 1rem;
    }
  }
}
