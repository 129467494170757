
.dimensions-container {
  text-transform: lowercase;

  .dimensions-group-title {
    font-style: italic;
  }
  .dimension {
    display: flex;
  }

  &.display-block {
    .dimensions-group:not(:last-child) {
      margin-bottom: 0.7rem;
    }
    .dimension {
      max-width: 22rem;

      .dimension-title {
        width: 6rem;
      }
      .dimension-text {
        vertical-align: top;
      }
    }
  }

  &.display-inline {
    display: flex;
    margin: 0 0.5rem;

    .dimensions-group {
      padding: 0.8rem;
      padding-left: 0.5rem;
      margin-right: 1rem;

      .dimensions-group-title {
        padding: 0.1rem;
        margin-right: 0.5rem;
      }
      .dimension, .dimension-notes {
        padding: 0.1rem;
        margin-right: 0.7rem;

        .dimension-title {
          margin-right: 0.3rem;
        }
      }
    }
  }
}
