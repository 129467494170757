@import 'styles/variables.scss';

$sidebarPosition: 13.1rem;

.sidebar {
  opacity: 1;
  transition: $standardTransitionTiming;
  transition-delay: $standardTransitionDelay;
  min-width: $sidebarWidth;
  width: $sidebarWidth;

  .sidebar-content {
    top: $sidebarPosition;
    margin: auto;
    margin-top: $sidebarPosition;
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;

    .sidebar-item, .sidebar-button {
      display: block;
      text-decoration: none;
      color: #000000;
      cursor: pointer;

      &:hover, &.active {
        color: #000000;
      }
    }
    .sidebar-button {
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(102, 102, 102, 1);
    }
    .sidebar-link {
      margin: 0.2rem 0rem;
      margin-left: 1rem;
      font-size: 0.7rem;
      color: rgba(128, 128, 128, 1);
    }
  }
}

@media #{$wideWindow} {
  .search-bar {
    margin-top: 1rem;
  }
}

@media #{$narrowWindow} {
  .content {
    .products-content {
      .sidebar {
        display: none;
      }
    }
  }
}
