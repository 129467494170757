// $productInstanceHeight: 13rem;
$productThumbnailHeight: 10rem;
$productHeaderHeight: 2rem;

.cart-page {
  margin: auto;
  margin-bottom: 5rem;

  .back-arrow-link {
    margin-bottom: 3rem;
    display: block;

    .back-arrow {
      height: 1rem;
      width: 2.5rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .items-content {
    .left-content {
      position: relative;
      min-height: 30rem;
      flex-grow: 1;
      padding-bottom: 5rem;

      .product-instances {
        .product-instance {
          margin: 1.5rem 0;

          .product-options-container {
            flex-grow: 1;
            display: flex;
            gap: 1rem;

            @media screen and (max-width: 800px) {
              flex-direction: column;
            }
            @media screen and (min-width: 801px) {
              flex-direction: row;
            }

            .left-container {
              flex: 1;

              .product-header {
                margin-bottom: 1rem;

                .product-title {
                  font-size: 1.2rem;
                  text-transform: lowercase;
                  color: #666;
                  text-decoration: none;
                }
              }

              .details-display {
                font-size: 0.9rem;
                text-transform: lowercase;
                display: table;

                .detail-display {
                  display: flex;
                  flex-direction: row;
                  padding: 0.3rem 0rem;
                  display: table-row;

                  .detail-type-title, .details-content {
                    display: table-cell;
                    padding: 0.3rem 0;
                  }

                  .detail-type-title {
                    opacity: 0.6;
                    width: 6rem;
                    flex-grow: 0;
                  }
                  .details-content {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    flex-grow: 1;

                    .detail {
                      display: flex;
                      flex-direction: row;
                    }
                    .detail-title, .detail-value {
                      margin-left: 0.5rem;
                    }
                    .detail-title {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
            .right-container {
              .price-and-editing-container {
                // @media screen and (max-width: 800px) {
                //   display: flex;
                //   flex-direction: column-reverse;
                // }

                .product-instance-buttons {
                  margin-top: 1rem;

                  @media screen and (max-width: 800px) {

                  }
                  @media screen and (min-width: 801px) {
                    text-align: right;
                  }
                }
                .price-display {
                  font-size: 0.9rem;
                  color: #666;

                  @media screen and (max-width: 800px) {

                  }
                  @media screen and (min-width: 801px) {
                    height: $productHeaderHeight;

                    &:not(.trade-price-displayed) {
                      justify-content: flex-end;
                    }
                  }
                }
                .product-instance-buttons {

                  .edit-button, .remove-button {
                    cursor: pointer;
                    opacity: 0.5;
                    font-size: 0.9rem;
                    color: #666;

                    &:hover {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          .product-instance-thumbnail {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: block;
          }
        }
      }
      .order-notes {
        font-size: 0.8rem;
        color: #666;
        line-height: 1.4;
        max-width: 35rem;

        .leadtime-order-note {
          margin-bottom: 0.25rem;
        }

        a {
          color: inherit;
        }
      }
    }

    .order-form-container {
      height: auto;

      .form-section {
        margin-bottom: 1rem;

        .form-section-title {
          margin-left: 1rem;
          margin-bottom: 0.5rem;
          color: rgba(125, 125, 125, 1);

          &.delivery-type {
            a {
              color: inherit;
              text-decoration: none;
              font-family: 'Source Serif';
              // border-radius: 50%;
              // border: 1px solid #666;
            }
          }
        }
        .text-input {
          margin-bottom: 0.25rem;
          width: 100%;
        }
        .delivery-address-inputs {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .input {
            &:not(:last-child) {
              margin-right: 0.25rem;
            }
          }
          .street-address-input, .city-input {
            width: 100%;
          }
          .state-input, .zip-input {
            width: 49%;
          }
          .state-input {
            .search {
              width: 100%;
            }
            .form-input {
              width: 100%;

              .field {
                width: 100%;

                .dropdown {
                  width: 100%;

                  .menu {
                    width: 100%;
                    // background-color: #efefef !important;
                  }
                }
                .text {
                  // position: static;
                  // padding: 0;
                }
                .default.text {
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .radio-input {
          width: 100%;
          justify-content: space-between;

          .checkbox {
            width: 49%;

            label {
              padding: 0.3rem;
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      .pricing-info {
        margin-bottom: 2.5rem;
        margin-top: 2.25rem;
        padding: 1rem;
        background-color: rgba(239, 239, 239, 1);

        .subtotal-line-item {
          margin: auto;
          width: 15rem;
          //font-size: 10pt;
          line-height: 1.4rem;
          font-size: 0.9em;

          .subtotal-line-label {
            display: inline-block;
            //padding-right: 1rem;
            width: 4rem;
            opacity: 0.5;
          }

          .subtotal-line-content {
            display: inline-block;
            width: 9rem;
            text-align: right;
            opacity: 0.7;
          }
        }
      }
    }

    .submit-button {
      width: 100%;
    }
  }

  .checkout-content {
    position: relative;

    .back-arrow {
      height: 1rem;
      width: 2.5rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .total-display {
      margin-bottom: 2rem;
      margin-left: 6rem;
      color: #000000;
      font-size: 20px;
    }
    .explanation {
      max-width: 30rem;
      margin-bottom: 1rem;
      margin-left: 6rem;
      font-size: 12px;
      line-height: 1.4;
      color: #7d7d7d;

      a {
        color: inherit;
      }
    }
    .cancel-button-wrapper {
      position: absolute;
      left: 0;
    }

    .checkout-form {
      width: 60rem;
      margin-left: 4rem;
    //  font-family: 'Work Sans', sans-serif;
      font-family: 'Source Serif Pro', serif;
      text-transform: lowercase;

      .button, label, .label {
        //text-transform: uppercase;
        font-family: inherit;
      }

      label, .label {
        color: #7d7d7d;
        padding: 0.5rem 1rem;
      }

      .menu {
        min-width: 100%;
      }

      input {
        font-family: inherit;
      }

      input, .dropdown {
        background-color: #efefef;
        border: none;
      }

      .payment-methods {
        .payment-method {
          .pm-header {
            .pm-header-text {
              text-transform: lowercase;
            }
          }

          .pm-body {
            padding-top: 3rem;

            .form-container {
              .form {
                .billing-state {
                  .selection {
                    text-transform: none;
                  }
                }
                .partial-payment-container {
                  .partial-payment-note {
                    text-transform: lowercase;
                    padding-left: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .submit-button {
    background-color: black;
    border-radius: 0px;
    color: white;
    text-transform: lowercase;
  }

  @media only screen and (min-width: 801px) {
    padding: 0 3.1rem;
    margin-top: 10rem;
    width: 100%;
    max-width: 75rem;

    .items-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: top;

      .back-arrow {
        position: absolute;
      }

      .product-instances {
        margin-top: 1.5rem;

        .product-instance {
          position: relative;
          display: flex;
          flex-direction: row;
          // height: $productInstanceHeight;

          .product-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: $productHeaderHeight;
          }
          .details-display {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(#{$productThumbnailHeight} - #{$productHeaderHeight});
          }
          .product-instance-thumbnail {
            margin-right: 2rem;
            height: $productThumbnailHeight;
            min-height: $productThumbnailHeight;
            width: $productThumbnailHeight*4/5;
            min-width: $productThumbnailHeight*4/5;
          }
        }
      }

      .order-form-container {
        width: 18rem;
        padding-left: 3rem;

        .form-section {
          width: 15rem;
        }

        .submit-button {
          text-align: center;
        }
      }
      .order-notes {
        position: absolute;
        bottom: 0rem;
      }
    }
    .total-number {
      margin-left: 15rem;
    }
  }
  @media only screen and (max-width: 800px) {
    padding: 0 1rem;
    margin-top: 5rem;

    .product-instances {
      .product-instance {
        .product-instance-thumbnail {
          width: calc(100vw - 2rem);
          height: calc(100vw*5/4 - 2rem);
          margin-bottom: 1rem;
        }
      }
    }
    .order-form-container {
      margin-top: 3rem;

      .submit-button {
        text-align: center;
      }
    }
  }

  @media print {
    .left-content {
      .back-arrow-link {
        display: none;
      }
    }
  }
}

.order-completed {
  width: 18rem;
  text-align: center;
  position: fixed;
  top: calc(50% - 4rem);
  left: calc(50% - 9rem);
  filter: opacity(0.4);

  a {
    color: inherit;
  }
}

.empty-cart {
  width: 10rem;
  text-align: center;
  position: fixed;
  top: calc(50% - 4rem);
  left: calc(50% - 5rem);
  filter: opacity(0.4);

  .keep-shopping {
    color: inherit;
  }
}
