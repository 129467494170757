.about-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8rem;

  .about-page {
    text-align: center;

    .about-page-title, .about-page-body {
      margin-bottom: 4rem;
    }
    .about-page-title {
      font-size: 2.2rem;
      color: rgba(102, 102, 102, 1);
    }
    .about-page-body {
      text-align: left;
      line-height: 2rem;
      font-size: 1.3rem;
      max-width: 45rem;
      margin-left: 3.1rem;
      margin-right: 3.1rem;
      color: rgba(102, 102, 102, 1);

      .about-page-header {
        text-transform: uppercase;
        letter-spacing: 2px;
      }
      a {
        color: rgba(128, 128, 128, 1);

        &:hover {
          color: #000000;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .about-page-wrapper {
    margin-top: 4.5rem;

    .about-page {
      .about-page-title {
        margin-bottom: 4.5rem;
      }

      .about-page-body {
        font-size: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        line-height: 1.8rem;
      }
    }
  }
}
