$narrowWindow: 'only screen and (max-width: 800px)';
$wideWindow: 'print, (min-width: 801px)';
$groupCellWidth: 18.1rem;
$productCellWidth: 20rem;
$sidebarWidth: 12rem;
$mainContentWidth: 3 * ($groupCellWidth + 2.1rem);
$standardTransitionTiming: opacity 0.6s;
$standardTransitionDelay: 0s;
$loadingSymbolSize: 7rem;

.loading-symbol {
  height: $loadingSymbolSize;
  width: $loadingSymbolSize;
  background-image: url('https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/loader-dark.gif');
  background-size: contain;
  position: fixed;
  top: calc(50% - 3.5rem);
  left: calc(50% - 3.5rem);
  filter: opacity(0.3);
}
