$swatchSize: 3rem;

.icon-grid {
  position: relative;

  .option-meta {
    margin-bottom: 0.4rem;

    .option-category-title, .note {
      height: 1.3rem;
      padding: 0.1rem;
      min-width: 25rem;
      font-size: 0.9rem;
      text-transform: lowercase;
    }
    .note {
      opacity: 0.6;
    }
    .option-category-title {
      &.clickable {
        cursor: pointer;

        &:hover {
          color: #000000;
        }
      }
    }
  }
  .option-swatches-container {
    flex-wrap: wrap;
    min-width: 20rem;
    width: 100%;
    display: flex;
    margin-left: -0.2rem;

    .option-swatch {
      height: $swatchSize;
      width: $swatchSize;
      background-color: grey;
      margin: 0.2rem;
      background-size: contain;
      border-radius: $swatchSize;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
      &.selected {
        border: 2px solid #000000;
      }
    }
  }

  .popup {
    .popup-close-button {
      opacity: 0.5;
    }
    .popup-option-title {
      padding: 0.8rem;
      font-weight: 500;
      font-size: 1.3rem;
      text-transform: capitalize;
      color: rgba(102, 102, 102, 1);
    }
    .option-category-title, .note {
      min-width: 0rem;
      margin-left: 1rem;
      text-transform: lowercase;
    }
    .option-swatches-container {
      min-width: 0rem;
      margin-left: 1rem;
      padding-right: 1rem;
    }
    .popup-option-description {
      color: rgba(128, 128, 128, 1);
      padding: 1rem;
      margin-top: 3rem;
    }
    .slideshow {
      &:not(.is-full-screen) {
        position: absolute;
        bottom: 3.1rem;
        height: 35%;
        width: 100%;
      }
    }
  }
}
