
.details {
  line-height: 1.6;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .details-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .details-group-header {
      height: 1rem;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      cursor: pointer;
      width: 8rem;
      vertical-align: top;
      color: rgba(102, 102, 102, 1);

      &:hover {
        color: #000000;
      }
      .arrow {
        width: 0.6rem;
        height: 0.6rem;
        opacity: 0.5;
        margin-right: 0.4rem;
      }
    }
    .detail-body {
      margin-left: 1rem;
      color: rgba(128, 128, 128, 1);
      font-size: 0.8rem;

      .price.crossed-out {
        color: rgba(102, 102, 102, 1);
        text-decoration: line-through;
      }
      .files-details {
        display: flex;
        flex-direction: column;

        .file-link {
          color: rgba(128, 128, 128, 1);
          text-decoration: none;
          text-transform: lowercase;

          &:hover {
            color: #000000;
          }
        }
      }

      &:not(.is-expanded) {
       display: none;
      }

      .detail-item {
        display: flex;
        max-width: 22rem;

        .detail-item-title {
          min-width: 5rem;
          max-width: 10rem;
        }
        .detail-item-option {
          padding-left: 1rem;
          vertical-align: top;

          &.clickable {
            cursor: pointer;

            &:hover {
              color: #000000;
            }
          }
        }
      }
      .popup {
        .detail-option-popup-title {
          padding: 0.8rem;
          font-weight: 500;
          font-size: 1.3rem;
          text-transform: capitalize;
          color: rgba(102, 102, 102, 1);
        }
        .detail-option-popup-description {
          color: rgba(128, 128, 128, 1);
          padding: 1rem;
          margin-top: 3rem;
        }
        .slideshow {
          &:not(.is-full-screen) {
            position: absolute;
            left: 0;
            bottom: 3.1rem;
            height: 35%;
            width: 100%;
          }
        }
      }
    }
  }
}
