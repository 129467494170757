@import 'styles/variables.scss';

.products-show {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 5rem;

  .show-content {
    max-width: 60rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .slideshow-container {
      height: 100%;
      width: 100%;
      height: 31.6rem;
      width: 25.6rem;
      padding: 0.8rem;
    }
    .left-content {
      margin: 0.8rem;
      position: relative;
      width: 24rem;

      .product-display {
        min-height: 26.5rem;
        width: 100%;

        .description-view {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          color: rgba(102, 102, 102, 1);
          font-size: 0.9rem;
          padding-right: 1.9rem;

          .main-description, .components-description {
            letter-spacing: -0.5px;
            line-height: 1.6;
          }
          .main-description, .components-description, .price-container {
            margin-bottom: 2rem;
          }
          .price-container {
            .price.crossed-out {
              line-height: 2rem;
              opacity: 0.5;
              text-decoration: line-through;
            }
            .trade-price-text {
              opacity: 0.5;
              display: inline;
            }
          }
        }
      }
      .buttons {
        font-style: italic;
        cursor: pointer;
        position: relative;

        & > div, & > a {
          font-size: 1rem;
          margin: 0.2rem;
          margin-right: 1rem;
          display: inline-block;
          text-decoration: none;
          color: rgba(128, 128, 128, 1);

          &:hover {
            color: #000000;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 801px) {
  .products-show {
    .description-view {
      min-height: 26.5rem;
    }
  }
}

@media only screen and (max-width: 575px) {
  .products-show {
    max-width: 25rem;

    .show-content {
      width: 100%;
      margin: auto;

      .left-content{
        width: 100%;
        max-width: 24rem;
      }
      .slideshow-container {
        height: calc(110vw + 1.3rem);
        width: 88vw;
        max-height: 32.9rem;
        max-width: 25.6rem;

        .slideshow {
          &:not(.is-full-screen) {
            .slideshow-media-container {
              height: calc(110vw);
              width: 88vw;
              max-height: 31.6rem;
              max-width: 25.6rem;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

//WARNING size not stored in variable because different from other similar views
@media only screen and (max-width: 1100px) {
  .products-show {
    margin: auto;

    .show-content {
      padding: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-flow: column;
      align-items: center;

      .left-content, .slideshow-container {
        padding-left: 0;
        padding-right: 0;
      }

      .left-content {
        order: 2;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        align-items: center;

        .product-display {
          min-height: 8rem;
          order: 2;

          .description {
            .description-text, .price {
              position: relative;
              top: 0;
              margin: 1rem 0;
            }
          }
        }
        .buttons {
          margin-top: 1rem;
          margin-bottom: 2.5rem;
          order: 1;
          width: 100%;
        }
      }
      .slideshow-container {
        margin-bottom: 2rem;
        order: 1;
      }
    }
  }
}
