@import 'styles/variables.scss';

.login-page {
  padding-left: 3.1rem;
  max-height: 40rem;
  position: relative;
  color: rgba(102, 102, 102, 1);

  .trade-registry-message {
    margin-bottom: 2rem;
    max-width: 20rem;
    font-size: 0.8rem;
    padding-right: 1rem;
    line-height: 1.4;
  }
  .trade-registry-form {
    &:not(.did-register) {
      .did-register-message {
        display: none;
      }
    }
    &.did-register {
      .form, .trade-registry-message {
        display: none;
      }
    }
    .did-register-message {
      font-size: 0.8rem;
      padding-right: 1rem;
      max-width: 20rem;
      line-height: 1.4;
    }

    .i-agree {
      font-size: 0.8rem;
      margin-top: 2rem;
      margin-bottom: 1rem;


      input[type='checkbox'], span {
        display: inline-block;
        vertical-align: middle;
      }

      input[type='checkbox'] {
        margin: 0;
        appearance: checkbox !important;
        margin-right: 1rem;
        margin-bottom: 0.1rem;
      }
      a {
        text-decoration: none;
        font-style: italic;
      }
    }
  }

  button {
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    background-color: transparent;
  }
  a {
    color: inherit;
  }
  &, a, input, select {
    font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif !important;
  }
  .error-messages-container {
    margin-top: 2rem;
    text-align: center;
    color: red;
  }
  .login-forms-container {
    display: flex;
    flex-direction: horizontal;

    .dividing-line {
      background-color: rgba(102, 102, 102, 1);
      margin: auto;
      opacity: 0.2;
    }
    .form-container {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      // align-items: center;

      .trade-site-title {
        position: absolute;

        @media screen and (max-width: 500px) {
          top: -5rem;
          font-size: 0.6rem;

          .narrow-tagline {
            display: block;
          }
          .wide-tagline {
            display: none;
          }
        }
        @media screen and (min-width: 501px) {
          white-space: nowrap;
          top: -3rem;
          font-size: 0.8rem;

          .narrow-tagline {
            display: none;
          }
        }

        span {
          letter-spacing: 2px;
          text-transform: uppercase;
          font-weight: 700;
          color: #000;
        }
        .wide-tagline, .narrow-tagline {
          opacity: 0.3;
        }
      }

      .form-title {
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
      .form-link {
        display: block;
        margin-bottom: 2rem;
        font-style: italic;
        font-size: 0.8rem;
        color: inherit;
        text-decoration: inherit;
      }

      .form-category-container {
        margin-bottom: 0.5rem;
      }

      form {
        .login-submit-button {
          margin-left: 1rem;
          height: 0.8rem;
          width: 2rem;
          cursor: pointer;
          opacity: 0.6;

          &:hover {
            opacity: 1;
          }
        }
        ::placeholder, .state-input input, .state-input .text, .menu {
          font-size: 0.9rem;
          font-style: italic;
          text-transform: lowercase;
        }
        input, .state-input {
          padding: 0.5rem 0rem;
          font-family: 'Source Serif Pro', serif;
          color: rgba(102, 102, 102, 0.8);
          border-bottom: 0.05rem solid rgba(102, 102, 102, 0.4);
          border-radius: 0px;
        }
        .state-input {
          position: relative;
          padding: 0;

          input {
            border: none;
          }
          .menu {
            background-color: white;
            z-index: 10;
            max-height: 10rem;
            overflow: auto;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);

            .text {
              padding: 0.3rem 0.5rem;

              &:hover {
                background-color: #ccc;
              }
            }
          }
          .text {
            padding: 0.3rem 0;
          }
          .search > .text {
            position: absolute;
            top: 0.3rem;
            width: 100%;
          }
        }
      }

      .submit-button {
        margin-top: 1.5rem;
        height: 1.2rem;
        width: 3rem;
        cursor: pointer;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  $dotSize: 10px;
  $blue: rgb(105, 139, 184);

  .blinking-dot {
    @keyframes blink {
      0% { opacity: 0.05; }
      50% { opacity: 1; }
      100% { opacity: 0.05; }
    }

    opacity: 0.05;
    width: $dotSize;
    height: $dotSize;
    border-radius: $dotSize;
    background-color: $blue;
    position: absolute;
    animation: blink 0.64s*8 infinite;
  }

  @media #{$wideWindow} {
    margin-top: 10rem;

    .blinking-dot {
      top: 0.6rem;
      left: 1.2rem;
    }
    .login-forms-container {
      flex-direction: row;
      // justify-content: center;

      .dividing-line {
        height: 20rem;
        width: 1px;
      }
    }
  }

  @media #{$narrowWindow} {
    max-width: 20rem;
    max-height: 60rem;
    margin-top: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .blinking-dot {
      top: 6.6rem;
      left: 1.3rem;
    }
    .login-forms-container {
      flex-direction: column-reverse;

      .login-form, .reset-password-form, .trade-registry-form {
        margin-top: 3rem;
      }

      .dividing-line {
        order: 1;
        height: 2rem;
        opacity: 0;
      }
      .right-form {
        order: 0;
      }
      .left-form {
        order: 2;
      }
    }
  }
}
