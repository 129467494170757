.popup {
  .ui.selection.dropdown {
    background-color: #e4e4e4;
    width: 4rem;
  }
}

.popup, .cart-page {
  .text-input input, .ui.selection.dropdown {
    background-color: rgba(239, 239, 239, 1);
  }
  .text-input input, .dropdown-input input, .dropdown-input .text {
    border-radius: 0px;
    padding: 0.3rem 1rem !important;
    // height: 1.6rem;
    font-size: 0.9rem !important;
  }
  .ui.selection.dropdown {
    &.search {
      position: relative;

      input {
        background-color: transparent;
        font-size: inherit;
        color: inherit;
        font-family: inherit;
        appearance: none;
        border: none;
      }
      & > .text {
        position: absolute;
        top: 0px;
      }
    }

    .menu {
      z-index: 10;
      overflow: auto;
      max-height: 16rem;
      width: 8rem;
      background-color: rgba(245, 245, 245, 1) !important;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
      border-radius: 0;

      .active {
        .text {
          font-weight: bold;
          border-radius: 0;
        }
      }

      .text {
        text-align: left;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
  .field {
    .selection {
      .text {
        padding: 0;
      }
      .menu {
        background-color: #FFFFFF;
        z-index: 10;
      }
    }
  }
  .icon {
    display: none;
  }
  &:hover {
    color: #000000;
  }

  .radio-input {
    display: flex;
    flex-direction: row;
    height: 1.6rem;
    margin-bottom: 0.3rem;
    width: auto;

    input {
      z-index: -1;
      display: none;
    }
    .radio {
      margin: 0 !important;

      &.checked {
        label {
          background-color: #000000;
          color: #FFFFFF;
        }
      }
      &:not(.checked) {
        background-color: #e4e4e4;

        &:hover {
          color: #FFFFFF;
          background-color: #000000;
        }
      }

      label {
        z-index: 1;
        font-size: 0.8rem;
        padding: 0.3rem 1rem;
        cursor: pointer;
      }
    }
  }
}
