.page-not-found-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-not-found {
    text-align: center;

    .main-copy, .secondary-copy, .link {
      text-transform: lowercase;
      margin-bottom: 2rem;
    }

    .main-copy {
      font-size: 2.2rem;
      color: rgba(102, 102, 102, 1);
    }
    .secondary-copy {
      font-size: 1.1rem;
    }
    .link {
      font-size: 1rem;
      text-decoration: none;
      color: rgba(102, 102, 102, 1);
      cursor: pointer;

      &:hover {
        color: #000000;
      }
    }
  }
}
