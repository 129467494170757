.po-header {
  position: fixed;
  right: 0px;
  text-transform: lowercase;
  text-align: right;
  color: gray;
  font-size: 0.9rem;
  z-index: 8;
  background-color: #FFFFFF;

  .user-name {
    opacity: 0.7;
  }
  .logout-login-link {
    margin-right: 1rem;
    cursor: pointer;
  }

  a {
    color: inherit;
  }
}

@media screen and (max-width: 800px) {
  .po-header {
    position: absolute;
    top: 100%;
    box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.1);
    padding: 1em 4vw;
    font-size: 0.8em;
    width: 100%;

    .user-name {
      display: none;
    }
  }
}
@media screen and (min-width: 801px) {
  .po-header {
    top: 5rem;
    padding-top: 1rem;
    padding-left: 1em;
    padding-right: 4em;
    padding-bottom: 1em;

    .user-name {
      display: inline-block;
      margin-right: 1rem;
    }
  }
}
