::-webkit-scrollbar {
  display: none;
}
.app {
  &, .content input, .content a, .content button {
    font-family: 'Source Serif Pro', serif;
  }
  .content .gdpr-banner a {
    font-family: inherit;
  }
  .header {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 15;
  }
  .content {
    min-height: calc(100vh - 3rem);
  }
}

//it says max-device-width is depreciated
@media screen and (max-width: 720px) {
  .html {
    font-size: 95%;
  }
}

@media print {
  .header, .footer {
    display: none !important;
  }
}

//popups
.popup {
  .popup-window {
    border-radius: 0px;
    // background-color: #eee;
    box-sizing: border-box;
    position: relative;
    height: auto;

    .popup-close-button {
      cursor: pointer;
      width: 2rem !important;
      height: 2rem !important;
      display: block;
      opacity: 0.6;
      display: block !important;
    }

    @media screen and (min-width: 801px) {

    }
    @media screen and (max-width: 800px) {
      padding: 2rem;
      width: calc(100% - 2rem) !important;
    }
  }

  &.type-fitted {
    .popup-content {
      padding: 0 !important;
    }
  }
}
