.slideshow {
  width: 100%;
  position: relative;

  .slideshow-media-container {
    z-index: 9;
    position: relative;

    .slideshow-medium {
      &.type-image, &.type-vimage {
        background-size: contain;
        background-position: center;
      }
      &.type-video {
        height: 100%;

        .slideshow-video-player {
          object-position: center;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }

   &.is-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;

    @media (min-width: 801px) {
      height: calc(100% - 3rem);
      top: 3rem;
    }
    @media (max-width: 800px) {
      height: 100%;
    }
    .slideshow-media-container {
      .drawing {
        filter: brightness(4);
      }
    }
    .fullscreen-background {
      width: 100%;
      height: 100%;
      background-color: #212121;
      z-index: 8;
      position: fixed;
      top: 0;
    }
    .slideshow-tray {
      justify-content: space-between;
      z-index: 10;
      opacity: 1;
      padding-right: 0.5rem;
      flex-grow: 1;
      background-color: #000;
      font-family: 'Work Sans';

      .dimensions-group, .dimension-notes {
        padding: 1.1rem 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 0.8rem;
        opacity: 0.7;

        .dimension-title {
          min-width: 5rem;
          opacity: 0.8;
        }
      }
      .dimensions-group {
        font-size: 0.8rem;
        white-space: nowrap;
      }
      .dimension-notes {
        max-width: 40rem;
        font-size: 0.7rem;
      }
      .slideshow-tray-controls {
        font-size: 1rem;

        .slideshow-button.enter-button-container {
          display: none;
        }
        .slideshow-button.exit-button-container {
          display: flex;
        }
        .left-button, .right-button {
          width: 2rem;
        }
        .exit-button {
          width: 1.7rem;
        }
        .pinterest-button {
          margin-top: 0.1rem;
          width: 1.2rem;
        }
      }
    }
  }
  &:not(.is-full-screen) {
    height: 100%;

    .slideshow-tray {
      position: absolute;
      right: 0;
      bottom: 0;
      justify-content: flex-end;
      top: 100%;
      width: 100%;
      opacity: 0.3;
      filter: invert(100%);

      .slideshow-tray-controls {
        font-size: 0.9rem;

        .enter-button-container {
          display: flex;
        }
        .exit-button-container {
          display: none;
        }
        .left-button, .right-button {
          width: 1.8rem;
        }
        .enter-button {
          width: 1.1rem;
        }
        .pinterest-button {
          margin-top: 0.2rem;
          width: 1.1rem;
        }
      }
    }
  }

  .slideshow-media-container {
    width: 100%;
    height: 100%;
    position: relative;

    .slideshow-medium {
      &.type-image, &.type-vimage, &.type-video {
        &.active {
          opacity: 1;
          z-index: 6;
        }
      }
      &.type-image, &.type-vimage {
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 1s;
        position: absolute;
        margin: auto;
        cursor: pointer;
        z-index: 0;

        .image-for-right-clicking {
          opacity: 0;
          width: 100%;
          height: 100%;
        }
      }
      &.type-video {
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 1s;
        position: absolute;
        cursor: pointer;

        .slideshow-video-player {
          height: 100%;
        }
      }
    }
  }

  .slideshow-tray {
    display: flex;
    width: 100%;
    color: white;
    min-height: 3.1rem;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;

    &.count-1 {
      .slideshow-tray-controls {
        .left-button, .right-button, .slideshow-index {
          display: none;
        }
      }
    }
    .slideshow-tray-controls {
      justify-content: flex-end;
      display: flex;

      .slideshow-index {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        min-width: 3.5rem;
      }
      .slideshow-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2.8rem;
        height: 3rem;

        &:hover {
          .left-button, .right-button, .enter-button, .exit-button, .pinterest-button {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .slideshow {
    .slideshow-tray {
      .dimensions-container {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 800px) {

  .slideshow.is-full-screen {
    .slideshow-tray {
      justify-content: flex-end;

      .dimensions-container {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .image-for-right-clicking {
    display: none;
  }
  .slideshow {
    .slideshow-tray {
      .slideshow-tray-controls {
        .slideshow-button.enter-button-container, .enter-button {
           display: none;
        }
      }
    }
  }
}
