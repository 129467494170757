@import 'styles/variables.scss';

.search-bar {
  .form {
    .text-input {
      ::placeholder {
        font-style: italic;
        opacity: 0.9;
      }
      input {
        padding: 0.3rem 0rem;
        font-family: 'Source Serif Pro', serif;
        color: rgba(102, 102, 102, 0.8);
      }
    }
  }
}

@media #{$wideWindow} {
  .search-bar {
    margin-top: 1.5rem;
    text-align: center;
    width: 8rem;

    &.mobile {
      display: none;
    }
    .form {
      .text-input {
        width: 100%;

        ::placeholder {
          text-align: left;
          font-size: 0.8rem;
        }
        input {
          padding-top: 0rem;
          text-align: left;
          border-bottom: 0.05rem solid rgba(102, 102, 102, 0.4);
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media #{$narrowWindow} {
  .search-bar {
    margin-top: 0;

    &.desktop {
      display: none;
    }
    ::placeholder {
      text-align: right;
      font-style: italic;
      opacity: 0.9;
      font-size: 0.9rem;
    }
    .input {
      width: 6rem;
    }
    input {
      padding: 0.5rem 0rem;
      text-align: right;
      width: 6rem;
      border-bottom: 0rem;
      font-size: 0.9rem;
    }
  }
}
