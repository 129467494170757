.subscribe-form {
  .ui.form {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(150,150,150,0.5);

    .text-input {
      width: auto;
      flex-grow: 1;

      input {
        background-color: transparent;
        padding: 0.3rem 0rem !important;
        color: #999 !important;
      }
    }
    .submit-input {
      padding: 0;
      background-color: transparent;
      color: #999;
    }
  }
}
