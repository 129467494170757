@import 'styles/variables.scss';

.products-index-container {
  position: relative;
  padding-bottom: 9rem;
  margin: auto;
  max-width: $mainContentWidth + $sidebarWidth * 2;
  display: flex;
  justify-content: flex-end;
}
.products-index-content {
  max-width: $mainContentWidth;
  width: 100%;
}
.grid-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

@media #{$wideWindow} {

}

@media #{$narrowWindow} {
  .sidebar {
    display: none;
  }
}
