@import 'styles/variables.scss';

.products-header {
  opacity: 1;
  transition: $standardTransitionTiming;
  transition-delay: $standardTransitionDelay;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: #FFFFFF;
  z-index: 10;
  font-size: 1rem;

  .show-header {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0.2rem;
    padding-left: 0.4rem;

    .show-title-container {
      display: flex;

      .show-title {
        text-transform: lowercase;
        color: rgba(102, 102, 102, 1);
        margin-bottom: 0.3rem;
      }
    }

    .sibling-product {
      font-style: italic;
      text-decoration: none;
      font-size: 0.8rem;
      margin-right: 1rem;
      opacity: 1;
      transition: $standardTransitionTiming;

      &.hidden {
        opacity: 0;
      }
      &:not(.active) {
        color: rgba(128, 128, 128, 1);
        cursor: pointer;
      }
      &:hover, &.active  {
        color: #000000;
      }
      &.active {
        cursor: default;
      }
    }
    .back-link {
      opacity: 1;
      transition: $standardTransitionTiming;

      &.hidden {
        opacity: 0;
      }

      .back-arrow {
        height: 0.5rem;
        width: 1.25rem;
        cursor: pointer;

        &.hidden {
          opacity: 0;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .index-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #FFFFFF;

    &.hidden {
      opacity: 0;
    }
    .index-header-title {
      display: flex;
      justify-content: center;
      font-family: 'Source Serif';
      text-align: center;
      color: rgba(102, 102, 102, 1);
      transition: $standardTransitionTiming;

      &.hidden {
        opacity: 0;
      }
      .henrybuilt {
        font-size: 2.3rem;
        text-transform: lowercase;
      }
      .group-title {
        font-size: 2rem;
        text-transform: lowercase;
      }
    }
    .back-link {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
      img {
        opacity: 0.6;
        transition: opacity 0.5s;
        transition-delay: 0.5s;
      }
    }
  }
}

@media only screen and (min-width: 801px) {
  .products-header {
    .show-header {
      .back-link {
        margin-right: 1rem;

        &.mobile {
          display: none;
        }
      }
      .show-title-container {
        flex-direction: column;

        .show-title {
          font-weight: 500;
          font-size: 1.3rem;
        }
      }
    }
    .index-header {
      height: 10rem;
      padding-bottom: 2rem;

      .index-header-title {
        height: 2.3rem;

        .back-link.landing {
          margin-top: 0.35rem;
        }
      }
      .back-arrow {
        margin-left: -3rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        margin-bottom: 0.4rem;
        height: 1rem;
        width: 2.5rem;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .products-header {
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    position: sticky;
    top: 0rem;
    font-size: 0.8rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    padding-right: 4vw;

    .show-header {
      min-height: 3.05rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: fit-content;
      max-width: 75vw;

      .back-link {
        &.desktop {
          display: none;
        }
      }

      .show-title-container {
        flex-direction: row-reverse;
        align-items: top;
        justify-content: flex-end;
        margin-top: 2rem;

        .show-title {
          text-align: right;
          font-size: 1.7em;
        }

        .back-link {
          margin-top: 0.45em;
          margin-right: 1.3em;

          .back-arrow {
            margin: 0;
            height: 0.8em;
            width: 2.2em;
          }
        }
      }
      .sibling-products-container {
        margin-bottom: 0.2rem;
        z-index: 65;

        .sibling-product {
          text-align: right;
          margin-right: 0rem;
          display: block;
        }
      }
    }
    .index-header {
      padding-top: 5rem;
      padding-bottom: 0.2rem;
      align-items: flex-end;

      .index-header-title {
        text-align: right;
        max-width: 70vw;

        .back-link {
          margin-right: 0.6em;

          .back-arrow {
            margin: 0;
            margin-top: 0.7em;
            margin-right: 0.6em;
            margin-left: -2.8em;
            height: 0.8em;
            width: 2.2em;
          }
        }

        .henrybuilt, .group-title {
          font-size: 1.7em;

          &.hidden {
            color: #999999;
          }
        }
      }
    }
  }
}
