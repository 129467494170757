@import 'styles/variables.scss';

.product-groups-grid {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: $groupCellWidth * 3;
  width: 100%;

  .group-cell {
    opacity: 1;
    transition: $standardTransitionTiming;
    padding: 0 1.05rem 2.1rem;
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: $groupCellWidth;

    .group-images-container {
      height: 20rem;
      position: relative;

      .group-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-color: rgba(250, 250, 250, 1);
        position: absolute;
        opacity: 0;
        transition: opacity 2s;

        &.active {
          opacity: 1;
        }
      }
    }
    .group-title {
      padding-top: 0.4rem;
      font-size: 0.8rem;
      color: rgba(102, 102, 102, 1);
      text-transform: lowercase;
    }
  }
}

@media #{$wideWindow} {
  .group-images-container:hover {
    opacity: 0.8;
  }
}
