.popup {
  .popup-window {
    position: relative;

    .popup-close-button {
      display: none;
    }
    .popup-content {
      padding: 1rem;
      height: 100%;
      overflow: auto;
      padding-bottom: 15rem;

      .product-meta {
        display: flex;

        .product-thumbnail {
          height: 5rem*1.5;
          width: 4rem*1.5;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .product-title {
          padding: 0 2rem;
          font-weight: 500;
          text-transform: lowercase;
          color: rgba(102, 102, 102, 1);
        }
      }
      form {
        text-transform: lowercase;

        .product-instance-prop {
          padding-top: 1rem;

          &.pulls-prop, &.productDetailOptions-prop {
            .icon-grid .option-category .option-swatches-container .option-swatch {
              width: 4rem;
              height: 4rem;
            }
          }

          .prop-header {
            margin-top: 1rem;
          }
          .icon-grid {
            .option-category {
              padding: 0.5rem 0;

              .option-category-title, .note {
                color: #666;
                min-width: 0px;
              }
              .note {
                display: block;
              }
              .option-swatches-container {
                min-width: 0px;

                .option-swatch {
                  width: 2.5rem;
                  height: 2.5rem;
                }
              }
            }
          }
          .icon {
            display: none;
          }
          &:not(.radio-input):hover {
            color: #000000;
          }
        }
        .dropdown-input {
          width: auto;
        }
        .linked-dimensions-selector {
          margin: 1rem 0;
        }
        .dimension-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-size: 0.8rem;

          .dimension-title, .dimension-fixed, .dimension-text {
            height: 1.6rem;
            margin: 1px;
            font-size: 0.9rem;
          }
          .dimension-title {
            width: 9rem;
            color: rgba(102, 102, 102, 1);
            padding: 0.3rem 0;
          }
          .dimension-fixed {
            width: 4rem;
            padding: 0.3rem 1rem !important;
            // text-align: center;
            font-style: italic;
          }
          .dimension-text {
            margin-left: 1rem;
          }
        }
      }
      .popup-buttons {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-display {
          padding: 1rem;
        }

        .cart-buttons {
          padding: 1rem;
          padding-top: 0.5rem;
          display: flex;
          justify-content: flex-end;
        }
      }

      @media only screen and (min-width: 501px) {
        .product-meta .product-title {
          font-size: 1.3rem;
        }
        .popup-buttons {

        }
      }
      @media only screen and (max-width: 500px) {
        .product-meta .product-title {
          font-size: 1rem;
        }
        .popup-buttons {
          .button, .price-display {
            font-size: 0.8rem;
          }
          .button {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
